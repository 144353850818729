var wow = new WOW({ offset: 70, mobile: false });
wow.init();
$(document).ready(function () {
	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});
	$("a.has-tooltip").tooltip({
		placement: "auto"
	});
	//if ($(".homepage-marker").length > 0) {
	//	$(".header__logo img, .testimonials__logo").addClass("wow zoomIn").attr({ "data-wow-delay": "0.5s", "data-wow-duration": "2.5s" });
	//} else {
	//	$(".header__logo img").css("visibility", "visible");
	//}
	function fixHeights() {
		$(".featured-categories__title").sameHeight();
		$(".testimonials .item").sameHeight();
	}
	setTimeout(fixHeights, 500);
	$(window).on("resize", function () {
		fixHeights();
	});
	$(".home-gallery__previous").click(function (e) {
		var img = $(".home-gallery__slide").first();
		img.animate({ width: 0 }, 1000, "linear", function () {
			img.remove();
			$(".home-gallery__inner").append(img);
			img.width(130);
		});
		e.preventDefault();
		return false;
	});
	$(".home-gallery__next").click(function (e) {
		var img = $(".home-gallery__slide").last();
		img.width(0);
		img.remove();
		$(".home-gallery__inner").prepend(img);
		img.animate({ width: 130 }, 1000, "linear");
		e.preventDefault();
		return false;
	});
});
